<template>
  <ProductsForm
    :composed="product.is_composition"
    :product="product ? product : getCurrentProduct()"
    @save="updateProduct"
  />
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    ProductsForm: () => import('./ProductsForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    product: {
      type: 'product',
      categories: [],
      segments: [],
      special_dates: [],
    },
    askToLeave: true,
  }),
  created() {
    this.getCurrentProduct()
  },
  methods: {
    getCurrentProduct() {
      this.$api
        .get(`products/${this.$route.params.id}`, {
          headers: {
            Authorization: `Beader ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.product = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    composed() {
      return this.product.is_composition
    },
    updateProduct() {
      this.askToLeave = false
      const formData = new FormData() // Criando um FormData
      const productKeys = Object.keys(this.product) // Armazenando todas as chaves do objeto de PRODUTO
      const productValues = Object.values(this.product) // Armazenando todos os valores do objeto de PRODUTO

      /*
       ** Instanciando o array de imagens do produto
       */
      var product = this.product.product_images
      var images_string = []

      /*
       ** Tratamento das imagens do produto
       */
      for (let i = 0; i < product.length; i++) {
        /*
         ** Criando um atributo chamado "color_id" dentro do File
         ** recebido pelo parâmetro "src" do PRODUTO
         */
        let nameFile
        if (product[i].id != undefined) {
          nameFile = product[i].name_file
        } else {
          nameFile = product[i].src.name
        }

        let str = {
          id: product[i].id !== undefined ? product[i].id : null,
          color_id: product[i].color_id,
          name_file: `${nameFile}`,
        }

        let newFile = new File([product[i].src], nameFile)
        newFile.color_id = product[i].color_id

        /*
         ** Definindo os índices do array "product_images" como File
         */
        product[i] = newFile
        /*
         ** Fazendo append do novo array para o form data
         */ formData.append('product_images[]', product[i])
        images_string.push(str)
      }

      /*
       ** Tratamento dos vídeos do produto
       */
      var videos = this.product.product_videos
      var videos_string = []
      for (let i = 0; i < videos.length; i++) {
        //Obtem o nome do arquivo
        let nameFile
        if (videos[i].id != undefined) {
          nameFile = videos[i].src
        } else {
          nameFile = videos[i].name
        }
        //montando o objeto
        let str = {
          id: videos[i].id ?? null,
          name_file: nameFile,
        }
        let newFile = new File([videos[i]], nameFile)
        videos[i] = newFile
        //montando o campo do formdata
        formData.append('product_videos[]', videos[i])
        //montando o array de objetos para comparar os campos
        videos_string.push(str)
      }
      formData.append('image_string[]', JSON.stringify(images_string))
      formData.append('video_string[]', JSON.stringify(videos_string))

      /*
      
       ** Enviar 'print_ids' com os IDs dos prints selecionados.
       .
       */
      if (this.product.prints && this.product.prints.length > 0) {
        const printIds = this.product.prints.map((item) => item.id)
        formData.append('print_ids', JSON.stringify(printIds))
      } else {
        // Se não houver prints, envie um array vazio
        formData.append('print_ids', JSON.stringify([]))
      }

      /*
       ** Fazendo append do restante das propriedades que não são imagens,
      
       */
      for (let i = 0; i < productKeys.length; i++) {
        // Ignorar o campo antigo de prints
        // if (productKeys[i] === 'product_print_types') continue

        if (productValues[i] !== null) {
          if (
            // productKeys[i] == 'product_categories' ||
            productKeys[i] === 'product_composition' ||
            productKeys[i] === 'product_vendors'
          ) {
            let stringValue = JSON.stringify(productValues[i])
            formData.append(productKeys[i], stringValue)
          } else if (
            productKeys[i] !== 'product_images' &&
            productKeys[i] !== 'product_videos'
          ) {
            formData.append(productKeys[i], productValues[i])
          }
        }
      }

      let categories = []
      categories.push(
        ...this.product.categories,
        ...this.product.segments,
        ...this.product.special_dates
      )
      formData.append('all_product_categories', JSON.stringify(categories))

      this.$api
        .post(`products/${this.$route.params.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Produto atualizado com sucesso',
            confirm: () => {
              this.getCurrentProduct()
              this.$store.commit('setModal', { show: false })
              this.$router.push({ name: 'products' })
            },
          })
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops! Falha ao tentar atualizar este produto',
            caption: 'Verifique se os campos estão preenchidos corretamente.',
            confirm: () => {
              this.$store.commit('setModal', { show: false })
            },
          })
        })
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
